import { SVGProps } from 'react';

const SvgMenu = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 18 13"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M0 .545v2h18v-2H0ZM0 5.545v2h18v-2H0ZM0 10.545v2h18v-2H0Z" />
  </svg>
);

export default SvgMenu;
