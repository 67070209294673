import { SVGProps } from 'react';

const NilLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="33"
    height="14"
    viewBox="0 0 33 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M0.530001 13V2.63001C0.530001 1.90467 0.711334 1.29267 1.074 0.794006C1.43667 0.284007 2.03733 0.029007 2.876 0.029007C3.46533 0.029007 3.95267 0.199007 4.338 0.539006C4.72333 0.879007 5.11433 1.37201 5.511 2.01801L10.951 10.807C11.053 10.9657 11.155 11.096 11.257 11.198C11.359 11.3 11.461 11.351 11.563 11.351C11.699 11.351 11.8067 11.3057 11.886 11.215C11.9653 11.113 12.005 10.9713 12.005 10.79V0.250006H13.96V10.739C13.96 11.487 13.7673 12.0877 13.382 12.541C13.008 12.9943 12.4527 13.221 11.716 13.221C11.0927 13.221 10.5883 13.0567 10.203 12.728C9.829 12.3993 9.472 11.963 9.132 11.419L3.573 2.49401C3.45967 2.31267 3.36333 2.17101 3.284 2.06901C3.20467 1.95567 3.097 1.89901 2.961 1.89901C2.791 1.89901 2.66633 1.95567 2.587 2.06901C2.519 2.18234 2.485 2.33534 2.485 2.52801V13H0.530001Z" />
    <path d="M17.3354 13V0.250006H19.2904V13H17.3354Z" />
    <path d="M22.6777 13V0.250006H24.6327V11.3H32.2147V13H22.6777Z" />
  </svg>
);
export default NilLogo;
