import {
  Home,
  Logout,
  Mail,
  Message,
  Settings,
  Storage,
} from '@mui/icons-material';
import { On3IconWordpress } from '@on3/icons';
import { useClickOutside } from '@on3/ui-lib/hooks/useClickOutside';
import { useAuth, useSite } from '@on3/ui-lib/index';
import { cmsUrl, databaseUrl } from '@on3/ui-lib/utils/api';
import { User } from 'components/Svg/User';
import Link from 'next/link';
import { useMemo, useRef, useState } from 'react';

import styles from './UserMenu.module.scss';

const Menu = () => {
  const { user, hasArticleEditAccess, hasRdbAccess, logout } = useAuth();
  const { siteUrls } = useSite();

  const sites = useMemo(
    () =>
      siteUrls?.filter((site) => site.isTeam && user?.si?.includes(site.key)) ??
      [],
    [siteUrls, user?.si],
  );

  const forums = useMemo(
    () => sites.filter((site) => site.forumKey) ?? [],
    [sites],
  );

  const settingsUrl = useMemo(() => {
    if (sites.length > 0) {
      return `${sites[0].url}/my/settings/`;
    }

    return '/my/settings/';
  }, [sites]);

  return (
    <nav data-menu-ui="user-menu">
      <ul>
        {sites.length > 0 ? (
          sites.map((site) => (
            <li key={site.key}>
              <Link href={site.url as string}>
                <Home />
                {site.siteName}
              </Link>
            </li>
          ))
        ) : (
          <li>
            <Link href="/">
              <Home />
              Home
            </Link>
          </li>
        )}
        {forums.map((forum) => (
          <li key={forum.key}>
            <Link href={forum.primaryForum?.url as string}>
              <Message />
              {forum.primaryForum?.title}
            </Link>
          </li>
        ))}
        <li>
          <Link href="/boards/conversations/">
            <Mail />
            Inbox
          </Link>
        </li>
        <li>
          <Link href={settingsUrl}>
            <Settings />
            Settings
          </Link>
        </li>
        {hasRdbAccess() && (
          <li>
            <Link href={databaseUrl} rel="noopener noreferrer" target="_blank">
              <Storage />
              Database Admin
            </Link>
          </li>
        )}
        {hasArticleEditAccess() && (
          <li>
            <Link
              href={`${cmsUrl}/wp-admin/`}
              rel="noopener noreferrer"
              target="_blank"
            >
              <On3IconWordpress />
              WordPress Backend
            </Link>
          </li>
        )}
        <li>
          <button onClick={() => logout()} type="button">
            <Logout />
            Sign Out
          </button>
        </li>
      </ul>
    </nav>
  );
};

export const UserMenu = () => {
  const ref = useRef<HTMLDivElement | null>(null);

  useClickOutside(ref, () => setIsMenuOpen(false));

  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  return (
    <div className={styles.block} data-menu-ui="user" ref={ref}>
      <button
        className={styles.button}
        name="user-menu"
        onClick={() => setIsMenuOpen(!isMenuOpen)}
        type="button"
      >
        <User />
      </button>
      {isMenuOpen && <Menu />}
    </div>
  );
};
