import clsx from 'clsx';

import { Espn, On3, Rivals, TwoFourSeven } from '../Svg';
import styles from './ServiceLogo.module.scss';

export type ServiceType = 'espn' | '247' | 'twofourseven' | 'rivals' | 'on3';

interface ServiceLogoProps {
  type: ServiceType;
  className?: string;
  [key: string]: any;
}

export const ServiceLogo = ({
  type,
  className,
  ...props
}: ServiceLogoProps) => {
  type = type.toLowerCase() as ServiceType;

  const logos = {
    espn: Espn,
    '247': TwoFourSeven,
    twofourseven: TwoFourSeven,
    rivals: Rivals,
    on3: On3,
  };

  const Logo = logos[type];

  if (!Logo) {
    return null;
  }

  return (
    <Logo
      className={
        className ??
        clsx(styles.base, {
          [styles.on3]: type === 'on3',
        })
      }
      {...props}
    />
  );
};
