import { Link, LinkProps } from '@mui/material';
import NextLink, { LinkProps as NextLinkProps } from 'next/link';
import { forwardRef } from 'react';

export interface ITextLink extends Omit<LinkProps, 'href'> {
  /** an `href` is required when using `TextLink` */
  href?: NextLinkProps['href'] | null;
}
export const TextLink = forwardRef<HTMLAnchorElement, ITextLink>(
  ({ children, href, ...restLinkProps }, ref) => {
    return (
      <NextLink href={href || '/'} legacyBehavior passHref>
        <Link ref={ref} underline="hover" {...restLinkProps}>
          {children}
        </Link>
      </NextLink>
    );
  },
);

TextLink.displayName = 'TextLink';
