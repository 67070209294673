import { Facebook, Instagram, Twitter, YouTube } from '@mui/icons-material';
import { On3IconForumsAlternate } from '@on3/icons';
import { useAuth } from '@on3/ui-lib/index';
import { useSite } from '@on3/ui-lib/src/contexts/SiteProvider';
import clsx from 'clsx';
import { useHeader } from 'components/Header';
import { Menu } from 'components/Header/Menu';
import { MobileMenu } from 'components/Header/MobileMenu/MobileMenu';
import { UserMenu } from 'components/Header/UserMenu';
import { SiteLogo } from 'components/Image/SiteLogo';
import CalloutButton from 'components/UI/Buttons/CalloutButton';
import { TextLink } from 'components/UI/Link/TextLink';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { CSSProperties } from 'react';

import { ScrollWrapper } from '../ScrollWrapper';
import styles from './KsrHeader.module.scss';

export const KsrHeader = () => {
  const {
    themes: { style },
  } = useHeader();
  const { currentSite, menuData } = useSite();
  const isKSRPlus = currentSite?.key === 395;
  const router = useRouter();
  const { user } = useAuth();

  return (
    <ScrollWrapper>
      <div className={styles.headerwrapper}>
        <header className={styles.header}>
          <div className={styles.topWrapper}>
            <div className={styles.topRow}>
              <div className={styles.topLeft}>
                <Link
                  className={clsx(styles.ksrLink, {
                    [styles.active]: !isKSRPlus,
                  })}
                  href="/teams/kentucky-wildcats/"
                >
                  KSR
                </Link>
                <Link
                  className={clsx(styles.ksrLink, {
                    [styles.active]: isKSRPlus,
                  })}
                  href="/sites/kentucky-wildcats/"
                >
                  KSR <span className={styles.plus}>Plus</span>
                </Link>
              </div>

              <div
                className={clsx(styles.socialLinksContainer, {
                  [styles.desktopIcons]: isKSRPlus,
                })}
              >
                {currentSite?.facebookProfile && (
                  <TextLink
                    className={styles.socialLink}
                    href={`https://www.facebook.com/${currentSite?.facebookProfile}`}
                    rel="noopener"
                    target="_blank"
                  >
                    <Facebook />
                  </TextLink>
                )}
                {currentSite?.twitterHandle && (
                  <TextLink
                    className={styles.socialLink}
                    href={`https://twitter.com/${currentSite?.twitterHandle}`}
                    rel="noopener"
                    target="_blank"
                  >
                    <Twitter />
                  </TextLink>
                )}
                {currentSite?.instagramProfile && (
                  <TextLink
                    className={styles.socialLink}
                    href={`https://www.instagram.com/${currentSite?.instagramProfile}/`}
                    rel="noopener"
                    target="_blank"
                  >
                    <Instagram />
                  </TextLink>
                )}

                <TextLink
                  className={styles.socialLink}
                  href="https://www.youtube.com/channel/UCxlgPWNLi8UInV0HHhfSQCg/featured"
                  rel="noopener"
                  target="_blank"
                >
                  <YouTube />
                </TextLink>
              </div>

              <div className={styles.topRight}>
                <a
                  className={styles.ksboard}
                  href="/boards/forums/ksboard.172/"
                >
                  <On3IconForumsAlternate />
                  <span>KSBoard</span>
                </a>
                {user?.a ? (
                  <UserMenu />
                ) : (
                  <Link
                    className={styles.login}
                    href={`${currentSite?.url}/login/?returnto=${router.asPath}`}
                  >
                    Login
                  </Link>
                )}
              </div>
            </div>
          </div>
          <div className={styles.menuwrapper}>
            <div className={styles.mobileMenu} style={style as CSSProperties}>
              <MobileMenu data={menuData} />
            </div>
            <Link
              className={styles.logo}
              href={`${currentSite?.url}/`}
              title="home"
            >
              <SiteLogo
                className={clsx(styles.teamlogo, {
                  [styles.ksrPlusLogo]: isKSRPlus,
                })}
              />
            </Link>
            <div className={styles.menu}>
              <Menu data={menuData} />
            </div>
            {isKSRPlus && !user?.has && (
              <CalloutButton
                className={styles.subButton}
                fullWidth={false}
                href={`${currentSite?.isTeam ? currentSite?.url : ''}/join/`}
              >
                Subscribe<span className={styles.extraText}> to KSR+</span>
              </CalloutButton>
            )}
          </div>
        </header>
      </div>
    </ScrollWrapper>
  );
};
