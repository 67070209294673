import { SVGProps } from 'react';

const SvgDownArrow = ({
  width = '1em',
  height = '1em',
  fill = '#fff',
  ...props
}: SVGProps<SVGSVGElement>) => (
  <svg
    width={width}
    height={height}
    fill={fill}
    viewBox="0 0 12 8"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M10.59.013 6 4.593 1.41.013 0 1.423l6 6 6-6-1.41-1.41Z" />
  </svg>
);

export default SvgDownArrow;
