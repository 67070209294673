import type { SVGProps } from 'react';

export const Close = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      fill="#A2A9AD"
      height="15"
      viewBox="0 0 15 15"
      width="15"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        height="2"
        transform="rotate(-135 12.7279 14.1421)"
        width="18"
        x="12.7279"
        y="14.1421"
      />
      <rect
        height="2"
        transform="rotate(135 14.1421 1.41431)"
        width="18"
        x="14.1421"
        y="1.41431"
      />
    </svg>
  );
};

export default Close;
