import { Button, ButtonProps, CircularProgress } from '@mui/material';

export interface IPrimaryButtonProps extends ButtonProps {
  isLoading?: boolean;
  icon?: JSX.Element;
  component?: React.ElementType;
  setOpen?: boolean;
  target?: string;
}

export const PrimaryButton = ({
  isLoading,
  children,
  fullWidth = true,
  variant = 'contained',
  disableElevation = true,
  className,
  ...restButtonProps
}: IPrimaryButtonProps) => {
  return (
    <Button
      className={className}
      color="primary"
      disableElevation={disableElevation}
      disabled={isLoading}
      fullWidth={fullWidth}
      variant={variant}
      {...restButtonProps}
    >
      {children}
      {isLoading && <CircularProgress color="inherit" size={20} />}
    </Button>
  );
};
