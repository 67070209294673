import { CSSProperties } from 'react';

interface ITeamAvatar {
  slug?: string | null;
  width?: number | string;
  height?: number | string;
  className?: string;
  src?: string | null;
  style?: CSSProperties;
  teamName?: string | null;
}

export const TeamAvatar = ({
  slug,
  width,
  height,
  teamName: team,
  className,
  src,
  style,
}: ITeamAvatar) => {
  const teamName = team || slug?.replaceAll('-', ' ') || 'Team';

  return (
    <img
      alt={`${teamName} Avatar`}
      className={className}
      height={height}
      loading="lazy"
      src={src || 'https://on3static.com/teams/default.svg'}
      style={style}
      title={teamName}
      width={width}
    />
  );
};
