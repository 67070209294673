import clsx from 'clsx';
import React, { useCallback, useEffect, useState } from 'react';

import styles from './ScrollWrapper.module.scss';

interface ScrollWrapperProps {
  children: React.ReactNode;
  className?: string;
}

export const ScrollWrapper = ({ children, className }: ScrollWrapperProps) => {
  const [show, setShow] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const threshold = 200;
  const controlNavbar = useCallback(() => {
    if (window.scrollY > lastScrollY && window.scrollY > threshold) {
      setShow(false);
    } else {
      setShow(true);
    }

    // remember current page location to use in the next move
    setLastScrollY(window.scrollY);
  }, [lastScrollY]);

  useEffect(() => {
    window.addEventListener('scroll', controlNavbar);

    return () => {
      window.removeEventListener('scroll', controlNavbar);
    };
  }, [controlNavbar, lastScrollY]);

  return (
    <div
      className={clsx(styles.base, className, {
        [styles.hidden]: !show,
        [styles.scrolled]: lastScrollY > 50,
      })}
    >
      {children}
    </div>
  );
};
