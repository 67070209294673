import { SVGProps } from 'react';

const SoftballLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="80"
    height="38"
    viewBox="0 0 80 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.3232 28.1168C7.5968 22.7664 12.9824 15.3744 15.376 15.3744C16.2208 15.3744 17.136 15.9376 16.9248 16.536C16.432 17.8384 16.256 18.0496 15.6576 19.1056C12.736 24.5264 11.6096 32.552 12.8768 33.4672C13.3344 33.784 13.8272 33.5024 14.1088 32.6576C15.1648 32.0944 17.3472 34.1008 15.1296 35.8256C14.2496 36.5296 12.384 36.4944 11.0816 34.9456C8.688 32.1296 11.2928 23.0128 13.8624 18.2256L11.2928 21.0416C10.1664 22.2032 7.4912 26.392 6.752 27.8352C4.992 31.144 4.4992 34.8048 2.528 33.2208C1.472 32.4112 3.1264 30.0528 4.3232 28.1168ZM4.3936 22.3792C9.4272 22.6608 15.7984 22.4848 18.9312 22.2736C20.0224 22.2384 20.0224 24.2096 18.5088 23.8224C19.2128 23.6816 6.3648 23.6464 3.6896 24.1392C2.2816 24.3504 3.232 22.344 4.3936 22.3792Z"
      fill="#FDF3E7"
    />
    <path
      d="M18.6144 22.5552C19.424 20.9712 21.6064 21.64 20.9376 23.1536L20.3744 24.4208L19.1776 27.624C18.4032 28.8208 17.7344 30.0176 17.2416 30.968C16.5024 32.4816 14.2144 31.8128 14.8832 30.616C16.3616 27.3424 16.8192 26.1104 18.6144 22.5552ZM20.7616 30.4048L23.648 24.8432C24.2112 23.7872 24.5984 23.1536 23.9648 23.5056C22.5216 24.28 18.9664 28.1168 17.8048 29.912L19.6 26.0048C21.536 23.9632 22.8384 22.8368 24.2464 21.9568C25.1968 21.3584 27.0272 23.6112 26.64 24.4208C25.584 26.6384 23.8592 29.032 23.4016 30.8976C22.9792 32.376 20.128 31.5664 20.7616 30.4048ZM25.5136 25.3712C27.4496 23.3296 28.1888 22.872 29.5968 21.9568C30.5472 21.3584 32.3776 23.6112 31.9904 24.4208C30.8992 26.8144 29.3504 28.5392 28.9984 30.5104C28.8576 31.3552 34.4544 24.9136 35.4048 23.7168C35.616 23.4 36.0384 23.928 35.7216 24.4208C34.56 25.9344 31.7088 30.1936 29.5616 31.848C28.2944 32.8336 25.8656 31.0032 26.5344 29.6656L28.9984 24.8432C29.5264 23.7872 29.9488 23.1536 29.3152 23.5056C27.872 24.28 25.7952 26.04 24.6336 27.8352L25.5136 25.3712Z"
      fill="#FDF3E7"
    />
    <path
      d="M44.1344 24.8784C40.2976 30.5104 36.9184 32.4816 34.6656 31.9184C32.2016 31.32 32.5184 28.7152 33.7504 26.2512C35.2288 23.3648 36.848 21.816 38.9952 21.2176C41.8112 20.4432 42.2336 23.4 40.9664 24.4208C38.7136 26.2512 36.5312 26.4272 35.792 27.3072C35.2992 27.8352 35.792 26.568 36.2848 26.2512C38.784 24.5968 40.72 23.048 39.6992 22.5904C38.8896 22.2032 37.6576 23.752 36.3904 26.04C35.1936 28.2224 35.1936 29.736 36.0032 30.0528C37.0592 30.44 39.84 28.9264 43.1488 24.9488C43.9936 23.9984 44.6976 24.0336 44.1344 24.8784Z"
      fill="#FDF3E7"
    />
    <path
      d="M43.184 20.936C43.6064 20.232 44.1344 19.9856 44.6272 19.9152C45.4016 19.8096 46.2816 20.3024 45.9648 21.2176C45.7888 21.7104 45.472 22.168 45.296 22.4848C45.0496 22.872 45.4368 23.1888 46.0704 22.5904C46.4928 22.168 46.9504 21.6752 47.3376 21.6752C48.4992 21.6752 49.4848 22.5552 49.2032 23.1888C48.112 25.5824 45.5424 28.7856 45.1904 30.792C45.0496 31.6368 51.2448 24.9136 52.1952 23.7168C52.4064 23.4 52.8288 23.928 52.512 24.4208C51.2096 26.392 47.9008 30.44 45.7536 32.0944C44.4864 33.08 42.2336 31.3552 42.7264 29.912C43.6768 27.3072 45.2256 25.7232 46.528 23.4C46.1056 23.752 44.5216 23.8576 43.9584 23.6464C42.7968 23.224 42.7616 21.5344 43.184 20.936ZM45.7184 23.4C44.6624 23.7872 44.2048 24.984 43.2896 26.0048C42.9376 26.2864 42.304 26.0048 42.832 25.2656C43.6416 24.3504 43.4656 23.2592 44.3808 22.52C44.8384 22.1328 44.2752 22.3792 44.8032 22.8016C44.9792 23.0128 45.7888 23.3296 45.7184 23.4Z"
      fill="#FDF3E7"
    />
    <path
      d="M50.7168 25.4768C51.5264 24.5616 52.6176 22.8368 53.5328 22.0976C54.4128 21.3584 56.56 22.2736 55.5744 24.1744C54.3776 26.5328 52.8288 28.5392 52.4768 30.5456C52.336 31.3904 57.9328 24.9488 58.8832 23.752C59.0944 23.4352 59.5168 23.9632 59.2 24.456C58.0384 25.9696 55.1872 30.2288 53.04 31.8832C51.7728 32.8688 49.344 31.0384 50.0128 29.7008L52.4768 24.8784C53.0048 23.8224 53.0752 23.6816 53.0048 23.7872C52.336 24.632 51.808 25.3712 51.0688 26.392C50.6816 26.6384 50.1888 26.2512 50.7168 25.4768ZM56.1024 18.4016C56.9824 18.472 57.3696 19.1056 57.0528 19.9152C56.6656 20.7952 56.1728 21.0416 55.0464 20.9008C54.2368 20.8304 53.9904 20.0912 54.2368 19.5984C54.624 18.7536 55.1168 18.296 56.1024 18.4016Z"
      fill="#FDF3E7"
    />
    <path
      d="M66.8384 23.8224C67.2608 23.5056 67.4368 23.8928 67.3664 24.2448C67.3312 24.3856 66.5216 25.4064 66.3808 25.5824C62.1216 31.4256 60.08 32.4464 57.8272 32.024C55.328 31.5664 56.032 28.7152 57.1936 26.216C58.5312 23.3296 60.8192 21.4288 63.072 21.3936C65.1136 21.3584 65.2896 23.5056 64.3392 24.7728C63.5648 25.8288 61.9808 25.2304 62.368 24.4912C62.896 23.5408 63.6704 22.5552 63.1424 22.52C62.2624 22.4496 61.1008 23.6816 59.8336 25.9696C58.6368 28.152 58.6368 29.6656 59.4464 29.9824C60.5024 30.3696 62.3328 29.5952 65.5008 25.4416C65.7824 25.16 66.6976 23.9632 66.8384 23.8224Z"
      fill="#FDF3E7"
    />
    <path
      d="M71.2032 26.5328C67.2256 32.6928 66.0992 32.552 64.9728 31.848C63.072 30.6864 64.0928 28.0464 65.4656 25.6528C66.9792 23.0128 69.6192 21.4992 71.344 21.6048C73.808 21.7808 74.3712 23.1184 73.984 24.1392C73.5616 25.2656 71.2736 24.8784 71.5904 24.1392C72.0832 23.0128 71.9776 22.7664 71.6608 22.6256C70.8512 22.344 69.1968 23.7872 67.9296 26.1104C66.7328 28.2928 66.3808 29.7712 67.2256 29.8416C67.6832 29.8768 68.7744 29.5952 70.992 26.04C71.6256 25.4064 71.7312 25.688 71.2032 26.5328ZM71.0976 25.8288C71.52 25.0544 73.7024 25.3008 73.3152 26.392C73.1744 26.7792 72.7872 27.3072 72.3648 28.0464C71.5552 29.4192 71.2032 30.5808 71.4144 30.5456C72.2592 30.4048 76.8704 24.9488 77.8208 23.752C78.032 23.4352 78.4544 23.9632 78.1376 24.456C76.976 25.9696 73.8784 30.4048 71.7312 32.0944C70.2528 33.256 68.2112 31.0032 68.9504 29.7008L70.64 26.6736C71.0272 25.9696 70.8512 26.2864 71.0976 25.8288Z"
      fill="#FDF3E7"
    />
    <path
      d="M13.968 16.6416C13.3696 19.3872 10.4832 21.112 7.66721 21.112C4.74561 21.0768 3.40801 19.2816 3.09121 17.8384C2.70401 16.2192 3.23201 13.4032 5.97761 12.8752C8.65281 12.3472 8.864 14.776 7.9488 15.832C7.28 16.5712 6.18881 15.5856 5.62561 15.9376C5.30881 16.1136 5.02721 16.4656 4.99201 16.9936C4.92161 17.9792 5.59041 19.5984 6.75201 19.9504C8.19521 20.408 9.84961 19.8448 10.8352 16.8176C11.8912 13.544 7.3504 9.98879 9.1104 5.97599C10.1664 3.51199 12.9824 2.27999 15.9744 2.77279C17.9104 3.08959 19.0016 5.16639 18.6144 6.32799C17.4528 10.024 14.7424 7.63039 15.3408 6.53919C15.904 5.62399 16.9952 4.21599 16.08 3.86399C14.4608 3.26559 12.3488 3.68799 11.3984 5.51839C10.1664 7.80639 14.848 12.4528 13.968 16.6416ZM22.2048 8.86239C24.704 9.28479 24.88 11.5728 23.5776 14.5296C22.2752 17.4512 19.6704 20.5488 17.0304 20.056C14.6016 19.5984 14.6368 16.8528 16.1152 13.72C17.4176 10.8688 19.5648 8.40479 22.2048 8.86239ZM18.368 17.9088C19.2832 18.2608 20.5856 16.8176 21.8176 14.5296C23.0496 12.1712 23.3664 10.0592 22.2752 10.0592C20.9728 10.0592 20.0928 11.2912 18.7904 13.72C17.5232 16.008 17.5232 17.592 18.368 17.9088ZM27.8016 12.2064C25.408 15.8672 24.704 16.184 23.472 16.4656C21.2192 17.0288 20.3744 14.4592 20.7264 12.84C20.9728 11.7136 21.4656 9.74239 22.8736 9.88319L23.6128 10.6928C21.8528 12.5232 23.0496 15.48 23.824 15.2688C24.5632 15.0576 24.8096 14.8112 27.3088 11.6432C27.7664 11.1856 28.1536 11.784 27.8016 12.2064Z"
      fill="#FACD01"
    />
    <path
      d="M26.7456 26.8496C25.6896 27.4128 24.2816 27.8 24 25.9696C23.5424 23.1536 25.408 20.5136 27.0272 16.5008C28.6112 12.5936 31.6032 2.49119 35.0528 1.85759C38.8544 1.15359 37.376 4.95519 35.0528 7.13759C33.5744 8.54559 32.2016 10.2 30.864 11.6784C29.7728 12.9104 30.5472 10.552 30.9344 10.2704C31.7792 9.63679 33.8912 7.06719 34.736 6.25759C36.2496 4.70879 36.672 2.42079 35.5456 3.01919C34.736 3.44159 31.1104 11.5728 30.5472 13.0512C30.4416 13.368 35.088 22.344 26.7456 26.8496ZM30.4416 8.93279C29.808 9.81279 27.5552 12.488 26.816 13.544C26.4288 13.8608 26.0768 13.368 26.6048 12.5584C27.2736 11.5024 30.336 8.01759 31.0048 7.10239C31.6736 6.15199 31.3216 6.92639 31.4624 6.89119L30.4416 8.93279ZM26.6752 24.9136C26.7104 25.864 29.8784 23.2592 30.4064 21.0768C30.7584 19.6688 30.512 17.5568 29.9488 15.48C26.992 18.9648 26.64 24.4912 26.6752 24.9136ZM33.3632 14.4944C34.1024 13.8608 34.8064 12.9456 35.3344 12.312C35.968 11.608 36.2144 12.2768 35.968 12.664C34.9472 14.424 34.1728 15.2688 32.0608 16.2192C30.1248 17.0992 27.696 16.9936 28.2592 16.0432C29.4912 14.8464 31.6736 15.9728 33.3632 14.4944Z"
      fill="#FACD01"
    />
    <path
      d="M38.8896 5.65921C40.2624 5.23681 41.6 4.18081 41.4944 5.80001C41.424 6.96161 39.84 9.07361 38.1856 11.96C36.8128 14.3888 35.9328 16.3248 35.5456 18.4368C35.3696 19.3168 41.3184 12.5232 42.304 11.2912C42.5504 10.9744 42.9728 11.5024 42.6208 12.0304C41.3888 13.6496 38.3968 18.0848 36.1088 19.8448C34.7712 20.8656 31.92 20.0912 33.3632 16.9584L35.44 12.488C35.7568 11.6784 38.3264 5.80001 38.8896 5.65921ZM35.8272 8.29921C39.7344 8.61601 39.0656 8.47521 41.4944 8.26401C42.3392 8.22881 42.4096 10.3056 41.2128 9.91841C38.0096 9.74241 38.608 9.70721 35.2992 9.95361C34.208 10.0944 34.912 8.26401 35.8272 8.29921Z"
      fill="#FACD01"
    />
    <path
      d="M46.3168 3.9696C47.056 2.9136 49.1328 3.2304 48.464 4.8496C47.4432 7.2784 45.1552 9.672 43.8176 12.6288L43.5008 14.776C42.5504 16.184 41.952 17.8032 41.3888 18.9296C40.6144 20.5488 38.2208 19.8096 38.8896 18.5424C40.4736 15.0928 43.9232 7.4544 46.3168 3.9696ZM41.2128 19.4224C41.072 19.3168 40.9664 19.2112 40.896 19.0704L41.8112 17.0992C42.0224 17.6624 41.5648 18.1552 42.2688 18.2608C43.9232 18.5072 46.1408 15.2336 47.4432 12.2064C47.936 11.08 48.1824 10.376 47.5136 10.728C45.8592 11.6432 44.0992 13.6848 42.9728 15.5856V15.5504C43.0432 15.48 43.0784 15.3744 43.1488 15.304L43.5712 12.6288C45.3664 10.7632 46.4576 9.9184 47.8656 9.0736C48.8864 8.44 51.0336 10.4464 50.5056 11.6784C49.696 13.6848 48.816 15.5152 47.8304 17.0288C46.176 19.4224 42.304 20.232 41.2128 19.4224ZM46.4576 17.8032C47.5488 20.2672 52.6176 13.192 53.9904 11.4672C54.2368 11.1504 54.7296 11.6432 54.3776 12.136C53.1808 13.7552 50.9984 18.0496 47.7952 19.528C47.2672 19.7744 45.7888 19.704 45.4016 18.7888L46.4576 17.8032ZM58.4256 13.72C58.3552 13.8256 58.2848 13.9312 58.2496 14.0016C58.2144 14.072 58.144 14.1776 58.0736 14.3184L56.3136 17.4864C56.2784 17.5568 56.2432 17.592 56.2432 17.6624C56.912 16.8176 57.7216 15.656 58.672 14.1776C59.2 13.3328 59.0944 13.016 58.4256 13.72ZM58.4256 13.72C58.3552 13.8256 58.2848 13.9312 58.2496 14.0016C58.2144 14.072 58.144 14.1776 58.0736 14.3184L56.3136 17.4864C56.2784 17.5568 56.2432 17.592 56.2432 17.6624C56.912 16.8176 57.7216 15.656 58.672 14.1776C59.2 13.3328 59.0944 13.016 58.4256 13.72Z"
      fill="#FACD01"
    />
    <path
      d="M65.9584 11.9952C64.7264 13.6144 61.4528 18.2608 59.2 20.0208C57.7216 21.1824 55.68 19.0704 56.208 17.6624C56.8768 16.8176 57.6864 15.656 58.6368 14.1776C59.2352 13.2976 59.0944 13.016 58.4256 13.6848C58.3552 13.7904 58.2848 13.896 58.2496 13.9664C58.4256 13.6496 58.3552 13.7904 58.5664 13.4384C59.024 12.5936 61.312 12.8752 60.9248 14.0368C60.784 14.4592 60.3616 14.9872 59.904 15.7616C59.0592 17.2048 58.672 18.4368 58.8832 18.3664C59.7632 18.1904 64.656 12.4528 65.6416 11.2208C65.888 10.9392 66.3104 11.5024 65.9584 11.9952Z"
      fill="#FACD01"
    />
    <path
      d="M61.5584 11.6784C61.1008 12.8752 58.672 12.4528 59.024 11.6784C59.5168 10.5168 59.4464 10.2352 59.0944 10.0944C58.2144 9.8128 56.4896 11.3264 55.1872 13.7552C53.92 16.0784 53.5328 17.6272 54.448 17.6976C54.9056 17.7328 56.032 17.4512 58.2496 14.0016C58.2144 14.072 58.144 14.1776 58.0736 14.3184L56.3136 17.4864C56.2784 17.5568 56.2432 17.592 56.2432 17.6624C53.9552 20.584 53.0048 20.3376 52.1248 19.7744C50.1536 18.5424 51.1744 15.7616 52.6176 13.2624C54.2368 10.4816 56.9824 8.8976 58.8128 9.0032C61.3824 9.2144 61.9456 10.6224 61.5584 11.6784ZM69.2672 4.0048C69.7952 3.2656 71.7664 3.0192 71.6256 4.6384C71.5552 5.8 69.3024 9.1088 67.5776 11.9248C66.1344 14.2832 65.3248 16.2896 64.9376 18.4016C64.7616 19.2816 70.7104 12.488 71.696 11.256C71.9424 10.9392 72.3648 11.4672 72.0128 11.9952C70.7808 13.6144 67.7888 18.0496 65.5008 19.8096C64.1632 20.8304 61.312 20.056 62.7552 16.9232L64.832 12.4528C65.184 11.6784 68.9152 4.4624 69.2672 4.0048Z"
      fill="#FACD01"
    />
    <path
      d="M75.3216 4.0048C75.8496 3.2656 77.8208 3.0192 77.68 4.6384C77.6096 5.8 75.3568 9.1088 73.632 11.9248C72.1888 14.2832 71.3792 16.2896 70.992 18.4016C70.816 19.2816 76.7648 12.488 77.7504 11.256C77.9968 10.9392 78.4192 11.4672 78.0672 11.9952C76.8352 13.6144 73.8432 18.0496 71.5552 19.8096C70.2176 20.8304 67.3664 20.056 68.8096 16.9232L70.8864 12.4528C71.2736 11.6784 74.9696 4.4624 75.3216 4.0048Z"
      fill="#FACD01"
    />
    <mask
      id="mask0_1_8"
      // style="mask-type:luminance"
      maskUnits="userSpaceOnUse"
      x="2"
      y="15"
      width="77"
      height="22"
    >
      <path
        d="M2 15.1984V36.4944H78.4544V15.1984H2ZM28.5056 25.6528L28.4704 25.5824L28.8928 24.808C28.9632 24.632 29.0688 24.4912 29.1392 24.3504C29.3152 23.9984 29.4912 23.6464 29.5264 23.5056C29.4912 23.5056 29.456 23.5408 29.3504 23.576C29.1392 23.7168 28.8576 23.8576 28.6112 24.0336H28.5056C29.1392 23.4 29.7728 22.5904 30.16 21.7808C30.4416 21.8512 30.7584 22.0272 31.1104 22.3088C30.5824 23.5056 29.7728 24.6672 28.5056 25.6528V25.6528Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_1_8)">
      <path
        d="M4.3232 28.1168C7.5968 22.7664 12.9824 15.3744 15.376 15.3744C16.2208 15.3744 17.136 15.9376 16.9248 16.536C16.432 17.8384 16.256 18.0496 15.6576 19.1056C12.736 24.5264 11.6096 32.552 12.8768 33.4672C13.3344 33.784 13.8272 33.5024 14.1088 32.6576C15.1648 32.0944 17.3472 34.1008 15.1296 35.8256C14.2496 36.5296 12.384 36.4944 11.0816 34.9456C8.688 32.1296 11.2928 23.0128 13.8624 18.2256L11.2928 21.0416C10.1664 22.2032 7.4912 26.392 6.752 27.8352C4.992 31.144 4.4992 34.8048 2.528 33.2208C1.472 32.4112 3.1264 30.0528 4.3232 28.1168ZM4.3936 22.3792C9.4272 22.6608 15.7984 22.4848 18.9312 22.2736C20.0224 22.2384 20.0224 24.2096 18.5088 23.8224C19.2128 23.6816 6.3648 23.6464 3.6896 24.1392C2.2816 24.3504 3.232 22.344 4.3936 22.3792Z"
        fill="#FDF3E7"
      />
      <path
        d="M18.6144 22.5552C19.424 20.9712 21.6064 21.64 20.9376 23.1536L20.3744 24.4208L19.1776 27.624C18.4032 28.8208 17.7344 30.0176 17.2416 30.968C16.5024 32.4816 14.2144 31.8128 14.8832 30.616C16.3616 27.3424 16.8192 26.1104 18.6144 22.5552ZM20.7616 30.4048L23.648 24.8432C24.2112 23.7872 24.5984 23.1536 23.9648 23.5056C22.5216 24.28 18.9664 28.1168 17.8048 29.912L19.6 26.0048C21.536 23.9632 22.8384 22.8368 24.2464 21.9568C25.1968 21.3584 27.0272 23.6112 26.64 24.4208C25.584 26.6384 23.8592 29.032 23.4016 30.8976C22.9792 32.376 20.128 31.5664 20.7616 30.4048ZM25.5136 25.3712C27.4496 23.3296 28.1888 22.872 29.5968 21.9568C30.5472 21.3584 32.3776 23.6112 31.9904 24.4208C30.8992 26.8144 29.3504 28.5392 28.9984 30.5104C28.8576 31.3552 34.4544 24.9136 35.4048 23.7168C35.616 23.4 36.0384 23.928 35.7216 24.4208C34.56 25.9344 31.7088 30.1936 29.5616 31.848C28.2944 32.8336 25.8656 31.0032 26.5344 29.6656L28.9984 24.8432C29.5264 23.7872 29.9488 23.1536 29.3152 23.5056C27.872 24.28 25.7952 26.04 24.6336 27.8352L25.5136 25.3712Z"
        fill="#FDF3E7"
      />
      <path
        d="M44.1344 24.8784C40.2976 30.5104 36.9184 32.4816 34.6656 31.9184C32.2016 31.32 32.5184 28.7152 33.7504 26.2512C35.2288 23.3648 36.848 21.816 38.9952 21.2176C41.8112 20.4432 42.2336 23.4 40.9664 24.4208C38.7136 26.2512 36.5312 26.4272 35.792 27.3072C35.2992 27.8352 35.792 26.568 36.2848 26.2512C38.784 24.5968 40.72 23.048 39.6992 22.5904C38.8896 22.2032 37.6576 23.752 36.3904 26.04C35.1936 28.2224 35.1936 29.736 36.0032 30.0528C37.0592 30.44 39.84 28.9264 43.1488 24.9488C43.9936 23.9984 44.6976 24.0336 44.1344 24.8784Z"
        fill="#FDF3E7"
      />
      <path
        d="M43.184 20.936C43.6064 20.232 44.1344 19.9856 44.6272 19.9152C45.4016 19.8096 46.2816 20.3024 45.9648 21.2176C45.7888 21.7104 45.472 22.168 45.296 22.4848C45.0496 22.872 45.4368 23.1888 46.0704 22.5904C46.4928 22.168 46.9504 21.6752 47.3376 21.6752C48.4992 21.6752 49.4848 22.5552 49.2032 23.1888C48.112 25.5824 45.5424 28.7856 45.1904 30.792C45.0496 31.6368 51.2448 24.9136 52.1952 23.7168C52.4064 23.4 52.8288 23.928 52.512 24.4208C51.2096 26.392 47.9008 30.44 45.7536 32.0944C44.4864 33.08 42.2336 31.3552 42.7264 29.912C43.6768 27.3072 45.2256 25.7232 46.528 23.4C46.1056 23.752 44.5216 23.8576 43.9584 23.6464C42.7968 23.224 42.7616 21.5344 43.184 20.936ZM45.7184 23.4C44.6624 23.7872 44.2048 24.984 43.2896 26.0048C42.9376 26.2864 42.304 26.0048 42.832 25.2656C43.6416 24.3504 43.4656 23.2592 44.3808 22.52C44.8384 22.1328 44.2752 22.3792 44.8032 22.8016C44.9792 23.0128 45.7888 23.3296 45.7184 23.4Z"
        fill="#FDF3E7"
      />
      <path
        d="M50.7168 25.4768C51.5264 24.5616 52.6176 22.8368 53.5328 22.0976C54.4128 21.3584 56.56 22.2736 55.5744 24.1744C54.3776 26.5328 52.8288 28.5392 52.4768 30.5456C52.336 31.3904 57.9328 24.9488 58.8832 23.752C59.0944 23.4352 59.5168 23.9632 59.2 24.456C58.0384 25.9696 55.1872 30.2288 53.04 31.8832C51.7728 32.8688 49.344 31.0384 50.0128 29.7008L52.4768 24.8784C53.0048 23.8224 53.0752 23.6816 53.0048 23.7872C52.336 24.632 51.808 25.3712 51.0688 26.392C50.6816 26.6384 50.1888 26.2512 50.7168 25.4768ZM56.1024 18.4016C56.9824 18.472 57.3696 19.1056 57.0528 19.9152C56.6656 20.7952 56.1728 21.0416 55.0464 20.9008C54.2368 20.8304 53.9904 20.0912 54.2368 19.5984C54.624 18.7536 55.1168 18.296 56.1024 18.4016Z"
        fill="#FDF3E7"
      />
      <path
        d="M66.8384 23.8224C67.2608 23.5056 67.4368 23.8928 67.3664 24.2448C67.3312 24.3856 66.5216 25.4064 66.3808 25.5824C62.1216 31.4256 60.08 32.4464 57.8272 32.024C55.328 31.5664 56.032 28.7152 57.1936 26.216C58.5312 23.3296 60.8192 21.4288 63.072 21.3936C65.1136 21.3584 65.2896 23.5056 64.3392 24.7728C63.5648 25.8288 61.9808 25.2304 62.368 24.4912C62.896 23.5408 63.6704 22.5552 63.1424 22.52C62.2624 22.4496 61.1008 23.6816 59.8336 25.9696C58.6368 28.152 58.6368 29.6656 59.4464 29.9824C60.5024 30.3696 62.3328 29.5952 65.5008 25.4416C65.7824 25.16 66.6976 23.9632 66.8384 23.8224Z"
        fill="#FDF3E7"
      />
      <path
        d="M71.2032 26.5328C67.2256 32.6928 66.0992 32.552 64.9728 31.848C63.072 30.6864 64.0928 28.0464 65.4656 25.6528C66.9792 23.0128 69.6192 21.4992 71.344 21.6048C73.808 21.7808 74.3712 23.1184 73.984 24.1392C73.5616 25.2656 71.2736 24.8784 71.5904 24.1392C72.0832 23.0128 71.9776 22.7664 71.6608 22.6256C70.8512 22.344 69.1968 23.7872 67.9296 26.1104C66.7328 28.2928 66.3808 29.7712 67.2256 29.8416C67.6832 29.8768 68.7744 29.5952 70.992 26.04C71.6256 25.4064 71.7312 25.688 71.2032 26.5328ZM71.0976 25.8288C71.52 25.0544 73.7024 25.3008 73.3152 26.392C73.1744 26.7792 72.7872 27.3072 72.3648 28.0464C71.5552 29.4192 71.2032 30.5808 71.4144 30.5456C72.2592 30.4048 76.8704 24.9488 77.8208 23.752C78.032 23.4352 78.4544 23.9632 78.1376 24.456C76.976 25.9696 73.8784 30.4048 71.7312 32.0944C70.2528 33.256 68.2112 31.0032 68.9504 29.7008L70.64 26.6736C71.0272 25.9696 70.8512 26.2864 71.0976 25.8288Z"
        fill="#FDF3E7"
      />
    </g>
  </svg>
);

export default SoftballLogo;
