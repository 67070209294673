import clsx from 'clsx';

import styles from './Button.module.scss';
import { IPrimaryButtonProps, PrimaryButton } from './PrimaryButton';

const CalloutButton = ({
  isLoading = false,
  children,
  onClick,
  fullWidth = true,
  type = 'submit',
  href,
  className,
}: IPrimaryButtonProps) => {
  return (
    <PrimaryButton
      className={clsx(styles.btncallout, className)}
      fullWidth={fullWidth}
      href={href}
      isLoading={isLoading}
      onClick={onClick}
      type={type}
    >
      {children}
    </PrimaryButton>
  );
};

export default CalloutButton;
