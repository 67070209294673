import { SVGProps } from 'react';

const SvgForumsAlternate = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.8 0H1.2C.54 0 0 .54 0 1.2V12l2.4-2.4h8.4c.66 0 1.2-.54 1.2-1.2V1.2c0-.66-.54-1.2-1.2-1.2Zm0 8.4H2.4L1.2 9.6V1.2h9.6v7.2Z"
      fill="#70DB6A"
    />
  </svg>
);

export default SvgForumsAlternate;
